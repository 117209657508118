<template>
  <div id="content">
    <h1>
      Passwort vergessen
    </h1>

    <div style="font-size: 110%; font-weight: bold;">
      Bitte geben Sie Ihre E-Mail-Adresse ein, mit der Sie bei forsa.omninet registriert sind.
    </div>

    <div>
      Sie erhalten daraufhin eine E-Mail mit einem Link, unter dem Sie ein neues Passwort vergeben können.
    </div>

    <StatusMessage
      v-if="appStatus !== 1"
    />

    <AppInput
      v-model="email"
      placeholder="E-Mail-Adresse"
      @enterPressed="submit"
    />

    <AppButton
      @click="submit"
      cls="material"
    >
      Absenden
    </AppButton>
  </div>
</template>


<script>

export default {

  name: 'ForgotPasswordContent',

  data(){
    return {
      email: null,
    }
  },

  methods: {

    submit(){

      this.clearAppStatus();

      if (!this.email) {

        this.appError('Bitte geben Sie Ihre E-Mail-Adresse in das Eingabefeld ein.')

        return false;
      }

      if (!this.validateEmail()) {

        this.appError(
          'Die eingegebene E-Mail-Adresse ist ungültig. Bitte überprüfen Sie die Eingabe und versuchen Sie es erneut.'
        );
        
        return false;
      }

      this.appWorking();

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-forgot-password',
        data: {
          email: this.email,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.appSuccess(
            'Eine E-Mail mit einem Link zur Wiederherstellung Ihres Passwortes wurde verschickt. Bitte prüfen Sie Ihr E-Mail-Postfach und folgen Sie den Anweisungen in der E-Mail.'
          );

          this.$router.push({name: 'portal'});
        }
      )
      .fail(
        resp => {

          if (resp.status === 408) {

            this.appError(
              'Die eingegebene E-Mail-Adresse ist nicht bei forsa.omninet registriert.', 
              null
            );

            return;
          }

          this.appError(
            'Ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.'
          );
        }
      );
    },

    validateEmail(){

      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(this.email.trim().toLowerCase()))
        return false;

      return true;
    },
  },
}
</script>


<style scoped>
#content > * {
  margin: 0 0 24px 0;
  hyphens: auto;
  max-width: 800px;
}
#content > h1 {
  margin-bottom: 48px;
}
</style>