<template>
  <div id="forgotPassword">

    <HorizontalContainer>

      <ForgotPasswordContent />

      <FullscreenSpinner />

    </HorizontalContainer>

    <Footer />

  </div>
</template>


<script>
import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

import ForgotPasswordContent from '@/components/sections/TheForgotPasswordContent'
import Footer from '@/components/sections/TheFooter'

export default {

  name: 'ForgotPassword',

  components: {
    HorizontalContainer,
    ForgotPasswordContent,
    Footer,
  },
}
</script>


<style scoped>
#forgotPassword {
  display: flex;
  flex-direction: column;
}
#forgotPassword > .hContainer {
  flex-grow: 1;
  padding: 48px 12px 24px 12px;
}
</style>